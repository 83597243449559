<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <b-button-toolbar class="mb-3">
                    <b-button-group class="ml-auto">
                        <b-button to="add">
                            <b-icon icon="plus" />
                            Добавить
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    ref="table"
                    striped
                    hover
                    :items="itemsProvider"
                    :fields="fields"
                    :busy.sync="isBusy"
                    :per-page="pagination.perPage"
                    :current-page="pagination.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <template #cell(actions)="row">
                        <b-button-toolbar>
                            <b-button-group class="ml-auto">
                                <b-button :to="{path:`${row.item.id}`}">
                                    <b-icon icon="info" />
                                </b-button>
                                <b-button
                                    v-if="$auth.check('admin')"
                                    :to="{path:`${row.item.id}/edit`}"
                                >
                                    <b-icon icon="pencil" />
                                </b-button>
                                <poster
                                    v-if="$auth.check('admin')"
                                    tag="b-button"
                                    :item-id="row.item.id"
                                    method="admin.stages.delete"
                                    success-message="Данные удалены"
                                    @complete="refreshTable"
                                >
                                    <b-icon icon="trash" />
                                </poster>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                    <template #cell(days)="row">
                        {{ row.item.startDays }} - {{ row.item.endDays }}
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle" />
                            <strong>Загрузка данных...</strong>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <pagination
            v-model="pagination"
            :link-gen="paginationLinkGen"
        />
    </b-container>
</template>

<script>
import Pagination from "./../../Pagination";

export default {
    components: {Pagination},
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "name",
                    label: "Название",
                },
                {
                    key: "days",
                    label: "Дни",
                },
                {
                    key: "shiftDays",
                    label: "День смены коллектора",
                },
                {
                    key: "createdAt",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "updatedAt",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "actions",
                    label: "",
                },
            ],
            isBusy: false,
            sortBy: this.$route.query.sortBy ?? null,
            sortDesc: this.$route.query.sortDesc === "true",
            pagination: {
                currentPage: parseInt(this.$route.query.currentPage ?? 1),
                perPage: parseInt(this.$route.query.perPage ?? 50),
                totalItems: 0,
            },
        };
    },
    watch: {
        "$route.query": {
            handler(query) {
                if (!query.currentPage || !query.perPage) {
                    this.$router.replace({query: this.getQueryParams({})}).catch(() => {});
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getQueryParams(options) {
            const params = Object.assign({
                currentPage: this.pagination.currentPage,
                perPage: this.pagination.perPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
            }, options);

            return Object.fromEntries(Object.entries(params).filter(([, v]) => v != null && !!v));
        },
        itemsProvider(ctx) {
            this.isBusy = true;

            const query = this.getQueryParams(ctx);

            this.$router.push({query}).catch(() => {});

            return this
                .jsonRpc("admin.stages.list", query)
                .then(async (result) => {
                    this.pagination.totalItems = result.totalItems;

                    return result.items;
                })
                .finally(async () => {
                    this.isBusy = false;
                });
        },
        paginationLinkGen(page) {
            return "?" + (new URLSearchParams(this.getQueryParams({currentPage: page}))).toString();
        },
        refreshTable() {
            this.$refs.table.refresh();
        },
    }
}
</script>
